import React, {Fragment} from 'react';
import styled from 'styled-components';
import {HelpMenu} from './HelpMenu';
import {Breakpoints, NavActions} from '../../Contexts/AppContext';
import {PrivacyPolicySections} from '../../Domain/Help';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft} from '../../Components/SvgIcon';
import {useDimension} from '../../Hooks/AppHooks';

const PrivacyPolicyPage = props => {
  const {pathname} = props.location;
  const {desktop} = useDimension();

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>隱私權政策</div>}
        />
      )}
      <Wrapper>
        <HelpMenu pathname={pathname} />
        <div className="right">
          <div className="header">隱私權政策</div>
          {PrivacyPolicySections.map((s, index) => (
            <section key={index} className="section">
              <div className="title">{s.title}</div>
              {s.data.map((c, idx) => (
                <div key={idx} className="content">
                  {c}
                </div>
              ))}
            </section>
          ))}
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;
  & > .right {
    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 32px;
    }
    & > .section {
      margin-bottom: 32px;
      & > .title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        margin-bottom: 8px;
      }
      & > .content {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        margin: 12px 0;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      & > .header {
        display: none;
      }
    }
  }
`;

export default PrivacyPolicyPage;
